
























































import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';
import { initTableSortAndFilter, updateTableSort, TableSortAndFilter } from '@/types/TableSortAndFilter';
import {
  DealerListDetailResponse,
  DealerListRequest,
  DealerListResponse,
  DealerResponse,
  initForm,
  resetForm,
  updateForm
} from '@/types/api/DealerList';
import SortableTable from '@/components/SortableTable.vue';
import ModalAddNewDealer from '@/components/modals/AddNewDealer.vue';
import ModalConfirmation from '@/components/modals/Confirmation.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import { cleanupFilterRequest, createFilterRequest } from '@/types/api/FilterRequest';
import debounce from 'debounce';
import Vue from 'vue';
import { ApiResponse } from '@/types/api/Response';
import { AxiosError } from 'axios';
const deleteUserConfirmationTitle = 'Delete Confirmation';
const deleteUserConfirmationMessage = '<p>Are you sure you want to delete this user?</p><p>This action cannot be undone!</p>';
export default Vue.extend({
  'name': 'DealerList',
  'components': {
    SortableTable,
    ModalAddNewDealer,
    ModalConfirmation
  },
  'watch': {
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  data () {
    const pagination = initPagination();
    const form = initForm();
    const tableFilter = initTableSortAndFilter();
    return {
      pagination,
      form,
      tableFilter,
      'tableHeader': [
        {
          'key': 'dealerDetail.dealerType.name',
          'display': 'Type',
          'width': 250,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'customId',
          'display': 'ID No.',
          'width': 120,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'name',
          'display': 'Name',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'email',
          'display': 'Email',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'phone',
          'display': 'Phone No.',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'createdAt',
          'display': 'Created Date',
          'width': 180,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'createdBy.name',
          'display': 'Created By',
          'width': 220,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'isActive',
          'display': 'Status',
          'width': 160,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': '',
          'display': '',
          'width': 150,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center font-weight-bold',
          'hasDisplay': true,
          'clickable': true,
          'event': 'dealerClicked'
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center text-uppercase',
          'hasDisplay': true,
          'classFn': (val) => {
            return (val && val !== 'INACTIVE') ? '' : 'text-danger font-weight-bold';
          }
        },
        {
          'cssClass': 'text-nowrap',
          'hasDisplay': false,
          'buttons': [
            {
              'cssClass': 'btn-iris-table btn-iris-edit',
              'event': 'edit'
            },
            {
              'cssClass': 'btn-iris-table btn-iris-delete',
              'event': 'delete'
            }
          ]
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as Array<DealerListDetailResponse>,
      'debounceGetList': null as unknown,
      'deleteUser': {
        'confirmationTitle': deleteUserConfirmationTitle,
        'confirmationMessage': deleteUserConfirmationMessage,
        'showConfirmButton': true,
        'discardButtonText': 'Discard',
        'userId': -1
      }
    };
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      this.axios
        .get('dealer/list', {
          'params': getRequest
        })
        .then(({ data }) => {
          const response = data as DealerListResponse;
          this.tableData = response.result.items;

          const paginationUpdate = response.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    addDealer () {
      resetForm(this.form);
      (this.$refs.modalAddNewDealer as any).create();
    },
    editDealer (rowIndex: number) {
      const dealerId = this.tableData[rowIndex].id;

      this.axios.get(`dealer/${dealerId}`).then(({ data }) => {
        const response = data as DealerResponse;

        updateForm(this.form, response.result);

        (this.$refs.modalAddNewDealer as any).update();
      });
    },
    deleteDealer (rowIndex: number) {
      this.deleteUser.userId = this.tableData[rowIndex]
        ? this.tableData[rowIndex].id
        : 0;

      this.deleteUser.confirmationTitle = deleteUserConfirmationTitle;
      this.deleteUser.confirmationMessage = deleteUserConfirmationMessage;
      this.deleteUser.showConfirmButton = true;
      this.deleteUser.discardButtonText = 'Discard';
      (this.$refs.deleteUserConfirmation as any).show();
    },
    confirmDeleteUser () {
      (this.$refs.deleteUserConfirmation as any).hide(() => {
        this.axios.delete(`dealer/${this.deleteUser.userId}`).then(({ data }) => {
          this.getList();

          this.deleteUser.confirmationTitle = 'Success';
          this.deleteUser.confirmationMessage = data.message;
          this.deleteUser.showConfirmButton = false;
          this.deleteUser.discardButtonText = 'Dismiss';
        }).catch((err:AxiosError) => {
          this.deleteUser.confirmationTitle = 'Error';
          if (err && err.response) {
            const data = err.response.data as ApiResponse;
            this.deleteUser.confirmationMessage = data.message;
          }
          this.deleteUser.showConfirmButton = false;
          this.deleteUser.discardButtonText = 'Dismiss';
        }).finally(() => {
          (this.$refs.deleteUserConfirmation as any).show();
        });
      });
    },
    discardDeleteUser () {
      (this.$refs.deleteUserConfirmation as any).hide();
    },
    submitForm () {
      if (this.form.dealerId === 0) {
        this.form.isSubmitting = true;
        const clonedForm = Object.assign({}, this.form);
        delete clonedForm.dealerId;
        this.axios
          .post('dealer', clonedForm)
          .then(() => {
            (this.$refs.modalAddNewDealer as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status === 422 &&
              err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddNewDealer as any).setErrors(result);
              }
            }
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      } else {
        this.form.isSubmitting = true;
        this.axios
          .put('dealer', this.form)
          .then(() => {
            (this.$refs.modalAddNewDealer as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
              err.response.status === 422 &&
              err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddNewDealer as any).setErrors(result);
              }
            }
          })
          .finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      }
    },
    viewDealer (row: number) {
      const dealer = this.tableData[row];

      this.$store.commit('updateViewDealerId', dealer.id);
      this.$router.push({
        'name': 'AdminDealerStockList'
      });
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    },
    exportAll () {
      this.axios({
        'url': 'Dealer/export',
        'method': 'GET',
        'responseType': 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dealers.xlsx'); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }
  }
});
